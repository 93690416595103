import type { SxProps } from '@mui/material';
import { alpha, Box, createTheme, Grid, ThemeProvider } from '@mui/material';
import { flatSx } from '@ocodelib/mui';
import theme from '@repo-ui/theme';
import clsx from 'clsx';
import MenuLongCard from './MenuLongCard';
import MenuSmallCard from './MenuSmallCard';

const typography = theme.typography;

const myTheme = createTheme({
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1180,
      xl: 1536,
    },
  },
});

interface Props {
  sx?: SxProps;
  className?: string;
}

export default function HomeMainMenu(props: Props) {
  const { sx, className } = props;

  return (
    <ThemeProvider theme={myTheme}>
      <Box
        className={clsx('HomeMainMenu-root', className)}
        sx={flatSx(
          {
            width: '100%',
          },
          sx,
        )}
      >
        <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
          <Grid container order={1} size={{ xs: 12, sm: 6, md: 4 }}>
            <Grid size={{ xs: 12 }}>
              <MenuSmallCard
                title="교과목"
                textColor="#fff"
                desc="교과목 연계 학습자료"
                bgColor={`radial-gradient(circle at 190% 270%, ${alpha('#FFB100', 0.7)} 0%, #26282a 75%)`}
                imgUrl="/_static/images/home_top_subject.png"
                href="/dtb/list/A06"
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <MenuSmallCard
                title="AI학습"
                textColor="#fff"
                desc="미래를 여는 경험"
                bgColor={`radial-gradient(circle at 170% -150%, ${alpha('#7EC72B', 0.7)} 0%, #26282a 75%)`}
                imgUrl="/_static/images/home_top_ai.png"
                href="/ai-learn/list/supervised"
              />
            </Grid>
          </Grid>

          <Grid container size={{ xs: 12, md: 4 }} sx={{ order: { xs: 0, md: 1 } }}>
            <Grid size={{ xs: 12 }}>
              <MenuLongCard
                title="코딩하기"
                textColor="#fff"
                desc="코딩의 즐거움, Ocode에서"
                bgColor={`radial-gradient(circle 270px at -10% 55%, ${alpha('#039BE5', 0.4)} 0%, #26282a 75%, transparent),
              radial-gradient(circle 250px at 120% 50%, ${alpha('#039BE5', 0.7)} 0%, #26282a 75%)`}
                imgUrl="/_static/images/home_top_coding.png"
                href="/coding/list"
              />
            </Grid>
          </Grid>

          <Grid container size={{ xs: 12, sm: 6, md: 4 }} order={3}>
            <Grid size={{ xs: 12 }}>
              <MenuSmallCard
                title="클래스"
                textColor="#fff"
                desc="함께 배우고 성장하는 공간"
                bgColor={`radial-gradient(circle at 0% 280%, ${alpha('#C862DA', 0.7)} 0%, #26282a 75%, transparent),
              radial-gradient(circle at 150% 120%, ${alpha('#C862DA', 0.7)} 0%, #26282a 75%)`}
                imgUrl="/_static/images/home_top_class.png"
                href="/classroom"
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <MenuSmallCard
                title="오픈강좌"
                textColor="#fff"
                desc="직접 만드는 나만의 강좌"
                bgColor={`radial-gradient(circle at 20% -120%, ${alpha('#FF4683', 0.8)} 0%, #26282a 75%)`}
                imgUrl="/_static/images/home_top_open.png"
                href="/openlecture"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
